<template>
  <div class="layout">
    <div class="container">
      <DebugHelper
        v-bind:output="$route.query.short_code"
      />

      <main class="layout__card card"
        v-show="isCardVisible"
      >
        <Header
          v-on:closeApp="closeApp"
        />

        <section class="card__body">
          <ErrorTab
            v-if="isSubmittingError"
            v-on:resetApp="resetApp"  
          />

          <FormTab
            v-else-if="!isSuccessSubmitting"
            v-on:onSubmitSuccess="setFormSuccess"
            v-on:onSubmitError="setFormError"
          />

          <SuccessTab
            v-else
            v-on:closeApp="closeApp"
          />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'

  import DebugHelper from '@/components/DebugHelper.vue'

  import ErrorTab from '@/components/Tabs/ErrorTab.vue'
  import FormTab from '@/components/Tabs/FormTab.vue'
  import SuccessTab from '@/components/Tabs/SuccessTab.vue'

  export default {
    components: {
      Header,
      DebugHelper,
      ErrorTab,
      FormTab,
      SuccessTab
    },

    data: () => ({
      isCardVisible: true,
      isSuccessSubmitting: false,
      isSubmittingError: false,
    }),

    methods: {
      closeApp() {
        this.isCardVisible = false
        
        parent.postMessage('onAppClose', '*')
      },

      resetApp() {
        this.isCardVisible = true,
        this.isSuccessSubmitting = false,
        this.isSubmittingError = false
      },

      setFormSuccess() {
        this.isSuccessSubmitting = true
      },

      setFormError() {
        this.isSubmittingError = true
      },
    },
  }
</script>