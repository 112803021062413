const maskPayment = function(value) {
  value = value.replace(/[^0-9]/g, '') 
    .replace(/\s/, '') 

  value = Number(value)

  return value ? new Intl.NumberFormat('ru').format(value % 1000000) : 0
}

const maskName = function(value) {
  return value.replace(/[^А-Яа-я\s]/, '')
    .replace(/\s\s/g, ' ')
    .replace(/^\s/g, '')
    .replace(/([А-Яа-я]{1,})[\s]([А-Яа-я]{1,})[\s]([А-Яа-я]{0,})[\s]/, '$1 $2 $3')
    .replace(/[А-Я]/g, x => x.toLowerCase())
    .replace(/^([А-Яа-я])/, x => x.toUpperCase())
    .replace(/\s[а-я]/g, x => x.toUpperCase())
}

export { maskPayment, maskName }