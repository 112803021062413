import axios from '@/axios'

const postOrdersShort = function(shortcode, body = {}) {
  return axios.post(`/orders_short/${ shortcode }/add`, body)
}

const askOrdersShort = function(shortcode, body = {}) {
  return axios.post(`/orders_short/${ shortcode }/ask`, body)
}

export { postOrdersShort, askOrdersShort }