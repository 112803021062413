<template>
  <form class="form"
    v-on:submit.prevent="submit"
  >
    <div class="card__section card__section_heading card-name">
      <div class="card-name__row">
        <div class="card-name__col card-name__col_img">
          <img class="card-name__img" src="@/assets/img/logo.png" alt="logo">
        </div>

        <div class="card-name__col card-name__col_text">
          <p class="card-name__text">Оставьте заявку на кредит</p>
        </div>
      </div>
    </div>

    <InputField
      label="Ваше имя"
      v-model="name"
      v-bind:errors="nameErrors"
      v-bind:showErrors="!!($v.$error && nameErrors.length)"
      v-bind:mask="maskName"
      v-on:blur="setMonthPayment"
    />

    <InputField
      label="Ваш телефон"
      placeholder="+7 (___) ___-__-__"
      vMask="+7 (9##) ###-##-##"
      v-model="phone"
      v-bind:errors="phoneErrors"
      v-bind:showErrors="!!($v.$error && phoneErrors.length)"
      v-on:blur="setMonthPayment"
    />

    <InputField
      label="Сумма покупки"
      suffix="₽"
      v-model="price"
      v-bind:mask="maskPayment"
      v-on:blur="setMonthPayment"
    />

    <PaymentField
      v-bind:value="monthPayment"
    />

    <Button
      type="submit"
      placeholder="Отправить заявку"
      v-bind:disabled="$v.$invalid && $v.$error"
    />

    <p class="card-text card-text_small">Оформление кредита возможно только для совершеннолетних граждан РФ, имеющих постоянную регистрацию по месту жительства</p>
  </form>
</template>

<script>
  import InputField from '@/components/Form/InputField.vue'
  import PaymentField from '@/components/Form/PaymentField.vue'

  import Button from '@/components/Button.vue'

  import {
    maskName, 
    maskPayment
  } from '@/helpers/masks'

  import { validationMixin } from 'vuelidate'

  import {
    required,
    minLength
  } from 'vuelidate/lib/validators'

  import {
    postOrdersShort,
    askOrdersShort
  } from '@/api/ordersShorts'

  import {
    withoutSpaces,
    onlyDigits,
    formatNumber
  } from '@/helpers/formaters'

  export default {
    components: {
      InputField,
      PaymentField,
      Button,
    },

    mixins: [ validationMixin ],

    data: () => ({
      name: null,
      phone: null,
      price: null,

      monthPayment: null,

      maskName: maskName,
    }),

    methods: {
      async submit() {
        this.$v.$touch()
        
        if (this.$v.$invalid) return
        
        const body = {
          sum: this.price ? withoutSpaces(this.price) : null,
          phone: this.phone ? onlyDigits(this.phone) : null,
          name: this.name,
        }
          
        try {
          await postOrdersShort(this.$route.query.short_code, body)
          this.$emit('onSubmitSuccess')
        }
        catch(error) {
          this.$emit('onSubmitError')
        }
      },

      async setMonthPayment() {
        if (this.$v.$invalid || !this.price) return

        const body = {
          sum: this.price ? withoutSpaces(this.price) : null,
          phone: this.phone ?  onlyDigits(this.phone) : null,
          name: this.name,
        }

        try {
          const response = await askOrdersShort(this.$route.query.short_code, body)
          this.monthPayment = formatNumber(response.data.data.month_payment)
        }
        catch(error) {}
      },
    },

    validations: {
      name: {
        required,
        minLength: minLength(2)
      },

      phone: {
        required,
        minLength: minLength(18)
      },
    },

    computed: {
      nameErrors() {
        const errors = []
        if (!this.$v.name.required) errors.push('Обязательное поле')
        if (!this.$v.name.minLength) errors.push('Минимальная длина - 2 символа')
        return errors
      },

      phoneErrors() {
        const errors= []
        if (!this.$v.phone.required) errors.push('Обязательное поле')
        if (!this.$v.phone.minLength) errors.push('Введите телефон полностью')
        return errors
      },

      maskPayment() {
        return maskPayment
      }
    },
  }
</script>