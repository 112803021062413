<template>
  <div class="card__section card__section_info card-info">
    <label class="label">
      <p class="label__text label-text"
        v-if="label"
      >{{ label }}</p>

      <div class="form-control-outer">
        <input class="form-control" type="text"
          v-bind:class="{ 'form-control_blocked': $props.blocked, 'form-control_invalid': showErrors }"
          v-bind:disabled="disabled"
          v-bind:placeholder="placeholder"
          v-model="dataValue"
          v-on:input="onInput($event)"
          v-on:blur="$emit('blur')"
          v-mask="vMask"
        >

        <div class="errors label__errors"
          v-if="showErrors"
        >
          {{ errors[0] }}
        </div>

        <div class="form-control-outer__caption caption form-control-outer__caption_price"
          v-if="suffix"
        >
          <span>
            <span>{{ suffix }}</span>
          </span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      disabled: Boolean,
      blocked: Boolean,
      value: String,
      showErrors: Boolean,
      errors: Array,
      suffix: String,
      mask: {
        type: Function,
        default: value => value
      },
      vMask: String,
    },

    data: () => ({
      dataValue: null,
    }),

    mounted() {
      this.dataValue = this.value
    },

    methods: {
      onInput(event) {
        const value = event.target.value
        
        this.dataValue = this.mask(value)

        this.$emit('input', value)
      },
    }
  }
</script>