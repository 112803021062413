<template>
  <div class="card-btn">
    <button class="btn"
      v-bind:type="type"
      v-bind:disabled="disabled"
      v-on:click="$emit('click')"
    >
      <span>{{ placeholder }}</span>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      disabled: Boolean,
      type: String,
      placeholder: String,
    }
  }
</script>