const withoutSpaces = string => string.replace(/\s/g, '')

const onlyDigits = string => string.replace(/[^0-9]/g, '')

const formatNumber = value => new Intl.NumberFormat('ru').format(Math.ceil(value))

export {
  withoutSpaces,
  onlyDigits,
  formatNumber
}