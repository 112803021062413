<template>
  <div>
    <div class="card__section card-name">
      <div class="card-name__row">
        <div class="card-name__col card-name__col_img">
          <img class="card-name__img card-name__img_success" src="@/assets/img/icons/success.svg" alt="logo">
        </div>

        <div class="card-name__col card-name__col_text">
          <p class="card-name__text">Спасибо за Вашу заявку!</p>
        </div>
      </div>
    </div>

    <p class="card__section card-text">Очень скоро мы с Вами свяжемся</p>
    
    <Button
      type="button"
      placeholder="Закрыть"
      v-on:click="$emit('closeApp')"
    />
  </div>
</template>

<script>
  import Button from '@/components/Button.vue'

  export default {
    components: { Button },
  }
</script>