<template>
  <div style="display: none;">
    <pre>{{  output }}</pre>
  </div>
</template>

<script>
  export default {
    props: [ 'output' ]
  }
</script>