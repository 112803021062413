<template>
  <div class="card__section card__section_info card-info">
    <label class="label">
      <div class="form-control-outer">
        <input class="form-control form-control_blocked" type="text" placeholder="Ежемесячный платеж" disabled>

        <div class="form-control-outer__caption caption form-control-outer__caption_info">
          <span
            v-if="$props.value"
          >
            <span>от {{ $props.value }} ₽</span>
          </span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      value: String,
    },
  }
</script>