<template>
  <div>
    <div class="card__section card-name">
      <div class="card-name__row">
        <div class="card-name__col card-name__col_text">
          <p class="card-name__text">Ошибка!</p>
        </div>
      </div>
    </div>

    <p class="card__section card-text"></p>

    <Button
      type="button"
      placeholder="Повторить"
      v-on:click="$emit('resetApp')"
    />
  </div>
</template>

<script>
  import Button from '@/components/Button.vue'

  export default {
    components: { Button },
  }
</script>